<template>
    <div class="wrap">
        <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 15px;"
                @click="onClickLeft" />
            <span style="font-size: 20px;color:#fff; font-weight: bold;">个人详情</span>
        </div>
        <div class="content">
            <div class="user_info">
                <div class="left">06</div>
                <div class="right">
                    <div><span class="name">徐文强</span><span class="sex">男 34岁</span></div>
                    <div class="num">123146546546546464</div>
                </div>
            </div>
            <div class="doctor_box">
                <div><span style="color:#999">费别：</span>军改
                </div>
                <div><span style="color:#999">余额：</span><span style="color:#F12C20">4564654</span>
                </div>
            </div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">电话：</span>18756712345</div>
            <div style="font-size: 16px;margin-top: 17px;"><span
                    style="color:#999;">现住址：</span>湖北省武汉市洪山区浪费几乎是立刻到发号施令开发函数的回复</div>
            <div style="font-size: 19px;margin-top: 40px;font-weight: bold;">联系人信息</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">姓名：</span>五里营</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">联系电话：</span>1234578</div>
            <div style="font-size: 19px;margin-top: 40px;font-weight: bold;">住院情况</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">门(急)诊诊断：</span>左侧腹股沟斜</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院科室/病区：</span>普外科病区</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院病情：</span><span
                    style="color:#FE5656 ">危</span></div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">护理等级：</span>一级</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院时间：</span>2019-10-21 08:50:56
            </div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">入院天数：</span>2天</div>
            <div style="font-size: 16px;margin-top: 17px;"><span style="color:#999;">出院时间：</span>2019-10-23 08:50:56
            </div>
        </div>
        <div class="fybtn">费用累计 ¥120563.02</div>
        <div class="czbtn">点击查看充值记录</div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        onClickLeft() {
            this.$router.back()
            // this.$router.push({ path: '/menu' })
        },
        onClickInfo() {
            this.$router.push({ path: '/jcInfo' })
        },
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background: #F6F6F6;
    height: 130vh;
    margin-bottom: -50px;
    .nav_bar {
        color: #fff;
        background-color: #1578F9;
        padding: 10px 0px 50px 0px;
        text-align: center;
    }

    .content {
        background: #FFFFFF;
        box-shadow: 0px 2px 2px 0px rgb(7 2 14 / 6%);
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: -30px 20px 0;

        .user_info {
            display: flex;
            align-items: center;

            .left {
                width: 43px;
                height: 43px;
                background: #FE5656;
                border-radius: 10px;
                font-size: 21px;
                line-height: 43px;
                text-align: center;
                color: #FFFFFF;
                margin-right: 20px;
            }

            .right {
                margin-top: -5px;

                .name {
                    font-weight: bold;
                    color: #333333;
                    font-size: 19px;
                    margin-right: 20px;
                }

                .sex {
                    font-size: 13px;
                    // font-weight: bold;
                    color: #999999;
                    margin-top: 5px;
                }

                .num {
                    font-size: 16px;
                    // font-weight: bold;
                    color: #999999;
                    margin-top: 5px;
                }
            }

        }

        .doctor_box {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            margin-top: 25px;
            padding-right: 40px;
            // padding: 0px 60px 16px 30px;
        }
    }

    .fybtn {
        margin: 20px 20px 10px 20px;
        padding: 10px 0px;
        background: #FFFFFF;
        border: 1px solid #1578F9;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #1578F9;
        line-height: 29px;
        text-align: center;
    }
    .czbtn {
        margin:0px 20px 50px 20px;
        padding: 10px 0px;
        background: #1578F9;
        border: 1px solid #1578F9;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        line-height: 29px;
        text-align: center;
    }
}
</style>